@import "variables";
@import "mixins";
@import "typo";


html { /* body won't work ¯\_(ツ)_/¯ */
    

    @include breakpoint(lg) {
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
    }
}

body {
    color: $dark-blue;
    background: $light-gray;

}

a {
    color: $dark-red;
}

header {
    width: 100%;
    color: $white;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include breakpoint(lg) {
        position: fixed;
        z-index: 999;
    }

    .brand img {
        max-width: 100px;
        margin: 12px $gbl-padding;

        @include breakpoint(md) {
            max-width: 150px
        }
    }

    .connect {

        .connect-inner {
            position: absolute;
            top: 15px;
            right: 45px;
            display: grid;
            align-items: center;
            gap: 20px;
            grid-template-columns: 35px 35px 30px;

            @include breakpoint(md) {
                grid-template-columns: 50px 50px 45px;
                gap: 40px;
                right: 60px;
            }
        }
    }
}

section {
    padding: 5vh 0;
    @include breakpoint(lg) {
        height: 100vh;
        width: 100vw;
        scroll-snap-align: start;
        scroll-snap-stop: always;
        scroll-snap-type: x proximity
    }

    &.bg-dark {
        background-color: $dark-blue;
        color: $white;
    }

    &.bg-gray {
        background-color: $gray;
    }

    > div {
        max-width: $max-width;
        margin: auto;
        padding: 2vh $gbl-padding;
    }

    .col-1-2 {

        @include breakpoint(md) {
            display: grid;
            gap: $gbl-padding * 4;
            height: 100vh;
            grid-template-columns: 1fr 2fr;
            align-items: center;
        }
    }

    .col-2 {

        @include breakpoint(md) {
            display: grid;
            gap: $gbl-padding * 4;
            height: 100vh;
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }

        img {
            width: $half-max;
            max-width: 100%;
            height: auto;
            transform: rotate(-2deg);
            box-shadow: 0 0 30px rgb(0 0 0 / 30%);
            border: 2px solid #e7f4f7;
            border-radius: 3px;
            margin-bottom: $gbl-padding;
        }

        &.reverse {
            
            div:nth-of-type(1) {
                order: 2;
            }
            div:nth-of-type(2) {
                order: 1;
            }

            img {
                transform: rotate(2deg);
            }
        }
    }

    .roadmap li {
        display: grid;
        grid-template-columns: 85px 1fr;
        padding-bottom: 12px;
    }
}

.styled-image {
    width: $half-max;
    max-width: 100%;
    height: auto;
    transform: rotate(-2deg);
    box-shadow: 0 0 30px rgb(0 0 0 / 30%);
    border: 2px solid #e7f4f7;
    border-radius: 3px;
    margin-bottom: $gbl-padding;
}

.embed__wrapper {
    position: relative;
    padding-top: 56%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.mint-amount {
    margin-bottom: 0;
    font-size: 24;
}
