@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Nunito+Sans:wght@400&display=swap');
$serif: "Caveat";
$sans-serif: "Nunito Sans";

body {
    font-family: $sans-serif, sans-serif;
}

p {
    margin-bottom: 1rem;
    line-height: 1.5;
}

p, ul, ol {
    font-size: 1.5rem;
    line-height: 1.5;
}

strong {
  font-weight: bold;
}

h1, h2, h3, h4, h5 {
    margin: 3rem 0 1.38rem;
    font-family: $serif, serif;
    font-weight: 400;
    line-height: 1.3;
  }
  
  h1 {
    margin-top: 0;
    font-size: 3.052rem;
  }
  
  h2 {font-size: 2.441rem;}
  
  h3 {font-size: 1.953rem;}
  
  h4 {font-size: 1.563rem;}
  
  h5 {font-size: 1.25rem;}
  
  small, .text_small {font-size: 0.8rem;}

  @include breakpoint(lg) {
    h1 {font-size: 4.052rem;}
    
    h2 {font-size: 3.441rem;}
    
    h3 {font-size: 2.953rem;}
    
    h4 {font-size: 2.563rem;}
    
    h5 {font-size: 2.25rem;}
  }