$white: #eee;
$black: #090909;
$dark-gray: #444;
$gray: #d2e4e9;
$light-gray: #e8f4f7;
$dark-red: #874242;
$dark-blue: #314346;

$max-width: 1400px;
$half-max: 700px;

// FONT WEIGHTS
$light: 300;
$regular: 400;
$bold: 600;


$gbl-padding: 25px;

// BREAKPOINTS
$sofha-xs: 576px;
$xs-max  : 599px;
$sm      : 600px;
$sm-down : 767px;
$md      : 768px;
$md-down : 991px;
$lg      : 992px;
$xl-down : 1199px;
$xl      : 1250px;
$xlplus  : 1314px;
$xxl     : 1440px;
$xxxl    : 1920px;
$max     : 2650px;